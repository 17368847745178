import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import SignIn from 'views/signin/SignIn';

import { useAuth } from 'auth';

const PrivateLayoutRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const { isSignedIn, hasTenant, isManager, isHardwareManager } = useAuth();

  const renderLayout = (rest, props) => (
    <Layout viewName={rest.viewName}>
      <Component {...props} />
    </Layout>
  );

  return (
    <Route
      {...rest}
      render={props => {
        if (isSignedIn) {
          if (hasTenant) {
            if (rest.manager) {
              if (isManager) {
                return renderLayout(rest, props);
              }
              if (rest.hardwareManager && isHardwareManager) {
                return renderLayout(rest, props);
              }
              return <Redirect to="/dashboard" />;
            }

            return renderLayout(rest, props);
          }
          return <Redirect to="/welcome" />;
        }
        return <SignIn {...props} />;
      }}
    />
  );
};

PrivateLayoutRoute.propTypes = {
  component: PropTypes.func.isRequired,
  layout: PropTypes.func,
  children: PropTypes.element,
};

PrivateLayoutRoute.defaultProps = {
  layout: undefined,
  children: undefined,
};

export default PrivateLayoutRoute;
