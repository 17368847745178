import React, { useContext } from 'react';
import { withSnackbar } from 'notistack';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { useAuth } from '../../../auth';
import { addEmailToCollection, formatEmailHardware } from '../../../utils/functions/emailHelpers';
import { FirebaseContext } from '../../../utils/firebase';
import HardwareRequestsForm from './HardwareRequestsForm';
import { useManagerEmails } from '../../../hooks/useManagerEmails';

const HARDWARE_REQUESTS = 'hardware_requests';

const HardwareRequestFormContainer = ({ enqueueSnackbar }) => {
  const { user } = useAuth();
  const { firebaseApp } = useContext(FirebaseContext);
  const managerEmails = useManagerEmails(false, true, true);

  const createHardwareRequest = (hardwareRequestObject, firebaseApp) => {
    const database = getFirestore(firebaseApp);

    return addDoc(collection(database, HARDWARE_REQUESTS), hardwareRequestObject);
  };

  const handleSubmit = ({ description, title }) => {
    const hardwareRequestObject = {
      userId: user.uid,
      userTenant: user.tenant.id,
      requestedAt: new Date(),
      description,
      title,
      status: 'pending',
      requestedBy: {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
      },
    };

    const emailObject = formatEmailHardware(
      [user.email, ...managerEmails],
      user.firstName,
      user.lastName,
      title,
      description,
      'pending',
    );

    createHardwareRequest(hardwareRequestObject, firebaseApp)
      .then(() => {
        enqueueSnackbar('Hardware request successfully submitted', {
          variant: 'success',
        });
        addEmailToCollection(firebaseApp, emailObject);
      })
      .catch(err => {
        enqueueSnackbar('Error when submitting hardware request', {
          variant: 'error',
        });
        console.error('Error writing document: ', err);
      });
  };

  return <HardwareRequestsForm onSubmitButtonClick={handleSubmit} />;
};

export default withSnackbar(HardwareRequestFormContainer);
