import React, { useState, useEffect, useContext, useCallback } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Avatar,
  TextField,
  TableSortLabel,
  FormControl,
  Select,
  MenuItem,
  ListItemText,
  Checkbox,
  Divider,
} from '@material-ui/core';
import {
  collection,
  doc,
  getDocs,
  getFirestore,
  query,
  updateDoc,
  where,
  deleteField,
} from 'firebase/firestore';
import { ConfirmationDialog } from 'components/ConfirmationDialog';
import { DarkTooltip } from 'components/DarkTooltip';
import { snapToArray } from '../../utils';
import { Portlet, PortletHeader, PortletLabel, PortletContent } from '../../components/Portlets';
import { Cell } from '../../components/Cell';
import { FirebaseContext } from '../../utils/firebase';
import { InlineEdit } from '../../components/InlineEdit';
import useStyles from './TeamMembersTable.css';
import { useAuth } from '../../auth';
import { useTenant } from '../../hooks/useTenant';

const MEMBER_ROLES = {
  MEMBER: 'Member',
  MANAGER: 'Manager',
  OFFICE_MANAGER: 'Office Manager',
  ACCOUNT_MANAGER: 'Account Manager',
  ARCHIVED: 'Archived',
  HARDWARE_MANAGER: 'Hardware Manager',
};

const TeamMembersTable = () => {
  const classes = useStyles();
  const { firebaseApp } = useContext(FirebaseContext);
  const tenant = useTenant();
  const { user } = useAuth();
  const [isLoading, setLoading] = useState(false);
  const [members, setMembers] = useState([]);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [orderDirection, setOrderDirection] = useState('desc');
  const [managers, setManagers] = useState([]);
  const [managerEmails, setManagerEmails] = useState([]);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [memberToArchive, setMemberToArchive] = useState(null);
  const [selectedRole, setSelectedRole] = useState('');
  const database = getFirestore(firebaseApp);

  const TOTAL_VACATION_DAYS = tenant?.vacation.totalDays;

  const getMembers = useCallback(() => {
    const conditions = [];
    switch (selectedRole) {
      case MEMBER_ROLES.MEMBER:
        conditions.push(where('roles.member', '==', true));
        break;
      case MEMBER_ROLES.MANAGER:
        conditions.push(where('roles.manager', '==', true));
        break;
      case MEMBER_ROLES.OFFICE_MANAGER:
        conditions.push(where('roles.officeManager', '==', true));
        break;
      case MEMBER_ROLES.ACCOUNT_MANAGER:
        conditions.push(where('roles.accountManager', '==', true));
        break;
      case MEMBER_ROLES.ARCHIVED:
        conditions.push(where('roles.archived', '==', true));
        break;
      case MEMBER_ROLES.HARDWARE_MANAGER:
        conditions.push(where('roles.hardwareManager', '==', true));
        break;
      default:
        break;
    }

    const queryUsers = query(
      collection(database, 'users'),
      where('tenant.id', '==', user.tenant.id),
      ...conditions,
    );

    getDocs(queryUsers)
      .then(snapshot => {
        const result = snapToArray(snapshot);
        let filteredResult = result;
        if (selectedRole === '') {
          filteredResult = result.filter(member => {
            return !member.roles.archived; // do not show archived members unless explicitly requested from filter dropdown
          });
        }

        setMembers(filteredResult);

        const loadedManagers = [];
        const loadedManagerEmails = [];
        result.forEach(member => {
          if (member.roles && member.roles.manager) {
            loadedManagerEmails.push(member.email);
            loadedManagers.push({
              firstName: member.firstName,
              lastName: member.lastName,
              email: member.email,
            });
          }
        });
        if (loadedManagerEmails.length > 0 && loadedManagers.length > 0) {
          setManagerEmails(loadedManagerEmails);
          setManagers(loadedManagers);
        }

        setFilteredMembers(filteredResult);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [database, user.tenant.id, selectedRole]);

  const updateMembers = update => {
    const result = filteredMembers.map(m => {
      if (m.id !== update.id) return m;
      return { ...m, ...update };
    });

    setFilteredMembers(result);

    getMembers();
  };

  const updateUser = async (id, data) => {
    await updateDoc(doc(database, 'users', id), data);
    return updateMembers({ id, data });
  };

  const getFirstAndLastName = email => {
    if (!email) return '';
    const manager = managers.find(m => m.email === email);

    return manager ? `${manager.firstName} ${manager.lastName}` : '';
  };

  const handleManagerChange = async (e, userId) => {
    const {
      target: { value },
    } = e;
    // selected managers emails
    const managersEmails = typeof value === 'string' ? value.split(',') : value;

    const selectedManagers = [];
    await Promise.all(
      managersEmails.map(async email => {
        const q = query(collection(database, 'users'), where('email', '==', email));

        const querySnapshot = await getDocs(q);
        let manager = {};

        // this list will always have one value because email is unique
        querySnapshot.forEach(doc => {
          manager = doc.data();
        });

        selectedManagers.push({
          firstName: manager.firstName,
          lastName: manager.lastName,
          email: manager.email,
        });
      }),
    );
    await updateUser(userId, {
      assignedManager: selectedManagers,
    });
    updateMembers({
      userId,
      assignedManager: selectedManagers,
    });
  };

  const handleArchiveMember = async (e, userId) => {
    const memberToArchive = members.find(member => {
      return member.id === userId;
    });

    setMemberToArchive(memberToArchive);
    setDialogOpen(true);
  };

  const onDismiss = () => {
    setDialogOpen(false);
  };

  const handleRoleChange = async (e, userId) => {
    let newRoles;
    switch (e.target.value) {
      case MEMBER_ROLES.MEMBER:
        newRoles = { member: true };
        break;
      case MEMBER_ROLES.MANAGER:
        newRoles = { manager: true };
        break;
      case MEMBER_ROLES.ACCOUNT_MANAGER:
        newRoles = { accountManager: true };
        break;
      case MEMBER_ROLES.OFFICE_MANAGER:
        newRoles = { officeManager: true };
        break;
      case MEMBER_ROLES.HARDWARE_MANAGER:
        newRoles = { hardwareManager: true };
        break;
      case MEMBER_ROLES.ARCHIVED:
        newRoles = { archived: true };
        break;
      default:
        newRoles = { member: true };
        break;
    }

    await updateUser(userId, { roles: newRoles });
  };

  const handleSortRequest = () => {
    const isAscending = orderDirection === 'asc';
    setOrderDirection(isAscending ? 'desc' : 'asc');
  };

  const filterMembers = e => {
    setFilteredMembers(
      members.filter(member => {
        return member.displayName.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1;
      }),
    );
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const stableSort = (rowArray, comparator) => {
    const stabilizedArray = rowArray.map((el, index) => [el, index]);
    stabilizedArray.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedArray.map(el => el[0]);
  };

  useEffect(() => {
    setLoading(true);
    getMembers();
  }, [getMembers, selectedRole]);

  const getOldVacation = availableDays => {
    if (availableDays > TOTAL_VACATION_DAYS) {
      return availableDays - TOTAL_VACATION_DAYS;
    }
    return 0;
  };

  const getCurrentVacation = availableDays => {
    if (availableDays <= TOTAL_VACATION_DAYS) {
      return availableDays;
    }
    return TOTAL_VACATION_DAYS;
  };

  const getCurrentRole = roles => {
    if (roles.member) {
      return MEMBER_ROLES.MEMBER;
    }
    if (roles.manager) {
      return MEMBER_ROLES.MANAGER;
    }
    if (roles.officeManager) {
      return MEMBER_ROLES.OFFICE_MANAGER;
    }
    if (roles.accountManager) {
      return MEMBER_ROLES.ACCOUNT_MANAGER;
    }
    if (roles.hardwareManager) {
      return MEMBER_ROLES.HARDWARE_MANAGER;
    }
    if (roles.archived) {
      return MEMBER_ROLES.ARCHIVED;
    }
    return MEMBER_ROLES.MEMBER;
  };

  const handleChange = event => {
    setSelectedRole(event.target.value);
  };

  const onArchive = () => {
    if (memberToArchive.roles && memberToArchive.roles.manager) {
      const promises = [];
      members.forEach(member => {
        if (member.assignedManager.email === memberToArchive.email) {
          const promise = new Promise(() => {
            updateUser(member.id, {
              assignedManager: deleteField(),
            });
          });
          promises.push(promise);
        } else if (Array.isArray(member.assignedManager)) {
          member.assignedManager.forEach((manager, index) => {
            if (manager.email === memberToArchive.email) {
              member.assignedManager.splice(index, 1);
              const promise = new Promise(() => {
                updateUser(member.id, {
                  assignedManager: member.assignedManager,
                });
              });
              promises.push(promise);
            }
          });
        }

        Promise.all(promises)
          .then(() => {
            handleRoleChange({ target: { value: 'Archived' } }, memberToArchive.id);
            setDialogOpen(false);
          })
          .catch(() => {
            setDialogOpen(false);
          });
      });
    } else {
      handleRoleChange({ target: { value: 'Archived' } }, memberToArchive.id);
      setDialogOpen(false);
    }
  };

  return (
    <Portlet className={classes.root}>
      <PortletHeader className={classes.header} noDivider>
        <PortletLabel
          subtitle={`${members.length} in total`}
          title="Team Members"
          className={classes.title}
        />
        <div className={classes.filters}>
          <TextField label="Employee" onChange={filterMembers} className={classes.search} />

          <FormControl className={classes.formControl}>
            <Select
              value={selectedRole}
              onChange={handleChange}
              className={classes.select}
              id="react-select"
              displayEmpty
              renderValue={selectedRole => {
                if (selectedRole === '') {
                  return <div>User Role</div>;
                }
                return selectedRole;
              }}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="">
                <div>User Role</div>
              </MenuItem>

              <MenuItem value={MEMBER_ROLES.MEMBER}>
                <div className={classes.explanationText}>
                  <ListItemText primary={MEMBER_ROLES.MEMBER} />
                  <span>(no admin role)</span>
                </div>
              </MenuItem>
              <MenuItem value={MEMBER_ROLES.MANAGER}>
                <div className={classes.explanationText}>
                  <ListItemText primary={MEMBER_ROLES.MANAGER} />
                  <span>(receives requests only from assigned users)</span>
                </div>
              </MenuItem>
              <MenuItem value={MEMBER_ROLES.OFFICE_MANAGER}>
                <div className={classes.explanationText}>
                  <ListItemText primary={MEMBER_ROLES.OFFICE_MANAGER} />
                  <span>(receives only approved edu budget requests)</span>
                </div>
              </MenuItem>
              <MenuItem value={MEMBER_ROLES.ACCOUNT_MANAGER}>
                <div className={classes.explanationText}>
                  <ListItemText primary={MEMBER_ROLES.ACCOUNT_MANAGER} />
                  <span>(receives all requests)</span>
                </div>
              </MenuItem>
              <MenuItem value={MEMBER_ROLES.HARDWARE_MANAGER}>
                <div className={classes.explanationText}>
                  <ListItemText primary={MEMBER_ROLES.HARDWARE_MANAGER} />
                  <span>(receives only hardware requests)</span>
                </div>
              </MenuItem>
              <MenuItem className={classes.filterArchivedBackground} value={MEMBER_ROLES.ARCHIVED}>
                <div className={classes.explanationText}>
                  <ListItemText primary={MEMBER_ROLES.ARCHIVED} />
                  <span>(archived members)</span>
                </div>
              </MenuItem>
            </Select>
          </FormControl>
        </div>
      </PortletHeader>
      <PerfectScrollbar>
        <PortletContent isLoading={isLoading} className={classes.portletContent} noPadding>
          {members.length ? (
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Employee</TableCell>
                  <TableCell>Email</TableCell>
                  <DarkTooltip
                    title={`Available days are the sum of old & current unused vacation days. All days over ${TOTAL_VACATION_DAYS} days are considered old vacation.`}
                  >
                    <TableCell key="availableDays">
                      <TableSortLabel active direction={orderDirection} onClick={handleSortRequest}>
                        Available days
                      </TableSortLabel>
                    </TableCell>
                  </DarkTooltip>
                  <TableCell>Old/Current Vacation</TableCell>

                  <TableCell className={classes.eduBudgetCell}>Education budget (EUR)</TableCell>
                  <TableCell>Assigned Manager</TableCell>
                  <TableCell>User Role</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {stableSort(filteredMembers, getComparator(orderDirection, 'availableDays')).map(
                  ({
                    id,
                    displayName,
                    email,
                    availableDays,
                    photoURL,
                    roles,
                    availableEduBudget,
                    assignedManager,
                  }) => {
                    const currentRole = getCurrentRole(roles);
                    // conversion for entries with one manager
                    let currentAssignedManagers = [];
                    if (assignedManager) {
                      currentAssignedManagers = Array.isArray(assignedManager)
                        ? assignedManager
                        : [assignedManager];
                    }

                    const currentAssignedManagerEmails = currentAssignedManagers.map(
                      manager => manager.email,
                    );
                    // (currentAssignedManagers);

                    return (
                      <TableRow className={classes.tableRow} hover key={id} data-cy={email}>
                        <Cell>
                          <div className={classes.employeeCell}>
                            <Avatar className={classes.avatar} src={photoURL} variant="rounded" />
                            <span>{displayName}</span>
                          </div>
                        </Cell>

                        <Cell>{email}</Cell>
                        <Cell>
                          <InlineEdit
                            id={id}
                            name="availableDays"
                            value={availableDays || 0}
                            onUpdate={updateUser}
                          />
                        </Cell>
                        <Cell>
                          {`${getOldVacation(availableDays)}/${getCurrentVacation(availableDays)}`}
                        </Cell>

                        <Cell>
                          <InlineEdit
                            id={id}
                            name="availableEduBudget"
                            value={availableEduBudget || 0}
                            onUpdate={updateUser}
                            min={0}
                          />
                        </Cell>
                        <Cell>
                          <FormControl fullWidth>
                            <Select
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                },
                                transformOrigin: {
                                  vertical: 'top',
                                  horizontal: 'left',
                                },
                                getContentAnchorEl: null,
                              }}
                              renderValue={selected =>
                                selected.map(manager => getFirstAndLastName(manager)).join(', ')
                              }
                              placeholder="Choose manager"
                              labelId="select-standard-label"
                              id="select-standard"
                              value={currentAssignedManagerEmails || []}
                              onChange={e => handleManagerChange(e, id)}
                              multiple
                              label="Manager"
                              data-cy="managerSelect"
                              className={classes.dropdown}
                            >
                              {managerEmails.map(manager => (
                                <MenuItem key={manager} value={manager}>
                                  <Checkbox
                                    checked={currentAssignedManagerEmails.indexOf(manager) > -1}
                                    color="primary"
                                  />
                                  <ListItemText primary={getFirstAndLastName(manager)} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Cell>
                        <Cell>
                          <FormControl fullWidth>
                            <Select
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                },
                                transformOrigin: {
                                  vertical: 'top',
                                  horizontal: 'left',
                                },
                                getContentAnchorEl: null,
                              }}
                              id="role-select"
                              renderValue={selectedRole => selectedRole}
                              value={currentRole}
                              onChange={e =>
                                e.target.value === 'Archived'
                                  ? handleArchiveMember(e, id)
                                  : handleRoleChange(e, id)
                              }
                              label="Role"
                              data-cy="roleSelect"
                            >
                              <MenuItem value={MEMBER_ROLES.MEMBER}>
                                <div className={classes.explanationText}>
                                  <ListItemText primary={MEMBER_ROLES.MEMBER} />
                                  <span>(no admin role)</span>
                                </div>
                              </MenuItem>
                              <MenuItem value={MEMBER_ROLES.MANAGER}>
                                <div className={classes.explanationText}>
                                  <ListItemText primary={MEMBER_ROLES.MANAGER} />
                                  <span>(receives requests only from assigned users)</span>
                                </div>
                              </MenuItem>
                              <MenuItem value={MEMBER_ROLES.OFFICE_MANAGER}>
                                <div className={classes.explanationText}>
                                  <ListItemText primary={MEMBER_ROLES.OFFICE_MANAGER} />
                                  <span>(receives only approved edu budget requests)</span>
                                </div>
                              </MenuItem>
                              <MenuItem value={MEMBER_ROLES.ACCOUNT_MANAGER}>
                                <div className={classes.explanationText}>
                                  <ListItemText primary={MEMBER_ROLES.ACCOUNT_MANAGER} />
                                  <span>(receives all requests)</span>
                                </div>
                              </MenuItem>
                              <MenuItem value={MEMBER_ROLES.HARDWARE_MANAGER}>
                                <div className={classes.explanationText}>
                                  <ListItemText primary={MEMBER_ROLES.HARDWARE_MANAGER} />
                                  <span>(receives only hardware requests)</span>
                                </div>
                              </MenuItem>
                              <Divider />
                              <MenuItem
                                className={classes.actionArchiveBackground}
                                value={MEMBER_ROLES.ARCHIVED}
                              >
                                <div>
                                  <ListItemText
                                    primary="Archive Member"
                                    primaryTypographyProps={{
                                      style: { color: '#D94844' },
                                    }}
                                  />
                                </div>
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Cell>
                      </TableRow>
                    );
                  },
                )}
              </TableBody>
            </Table>
          ) : (
            <div className={classes.empty}>There are no members in this team.</div>
          )}
        </PortletContent>
      </PerfectScrollbar>
      <ConfirmationDialog
        id="confirmation-dialog-description"
        setDialogOpen={setDialogOpen}
        isDialogOpen={isDialogOpen}
        member={memberToArchive}
        onArchive={onArchive}
        onDismiss={onDismiss}
      />
    </Portlet>
  );
};

export default TeamMembersTable;
