import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { DashboardLayout } from '../components/Layout';
import PrivateLayoutRoute from './PrivateLayoutRoute';
import {
  SignIn,
  WelcomeView,
  TeamVacationRequestsView,
  DashboardView,
  VacationsView,
  TeamMembersView,
  OutOfOfficesView,
  HardwareRequestsView,
  TeamHardwareRequestsView,
  CalendarView,
  EducationBudgetRequestView,
  TeamEducationBudgetRequestView,
} from '../views';
import { useAuth } from '../auth';

const Routes = () => {
  const { isSignedIn } = useAuth();
  return (
    <Switch>
      <Redirect exact from="/" to="/dashboard" />
      <Route
        exact
        path="/signin"
        render={props => (isSignedIn ? <Redirect to="/dashboard" /> : <SignIn {...props} />)}
      />
      <Route
        exact
        path="/welcome"
        render={props => (isSignedIn ? <WelcomeView {...props} /> : <Redirect to="/signin" />)}
      />
      <Route component={WelcomeView} exact path="/welcome" />

      <PrivateLayoutRoute
        layout={DashboardLayout}
        component={DashboardView}
        exact
        viewName="Dashboard"
        path="/dashboard"
      />
      <PrivateLayoutRoute
        layout={DashboardLayout}
        component={TeamVacationRequestsView}
        exact
        viewName="Team Vacation Requests"
        path="/team-vacation-requests"
        manager
      />
      <PrivateLayoutRoute
        layout={DashboardLayout}
        component={VacationsView}
        exact
        viewName="My Vacation Requests"
        path="/my-vacation-requests"
      />
      <PrivateLayoutRoute
        layout={DashboardLayout}
        component={OutOfOfficesView}
        exact
        viewName="My Out of Office Requests"
        path="/my-out-of-office"
      />
      <PrivateLayoutRoute
        layout={DashboardLayout}
        component={EducationBudgetRequestView}
        exact
        viewName="My Education Budget"
        path="/my-education-budget"
      />
      <PrivateLayoutRoute
        layout={DashboardLayout}
        component={HardwareRequestsView}
        exact
        viewName="My Hardware Requests"
        path="/my-hardware-requests"
      />
      <PrivateLayoutRoute
        layout={DashboardLayout}
        component={TeamMembersView}
        exact
        viewName="Team Members"
        path="/team-members"
        manager
      />
      <PrivateLayoutRoute
        layout={DashboardLayout}
        component={TeamHardwareRequestsView}
        exact
        viewName="Team Hardware Requests"
        path="/team-hardware-requests"
        manager
        hardwareManager
      />
      <PrivateLayoutRoute
        layout={DashboardLayout}
        component={TeamEducationBudgetRequestView}
        exact
        viewName="Team Education Budget"
        path="/team-education-budget-requests"
        manager
      />
      <PrivateLayoutRoute
        layout={DashboardLayout}
        component={CalendarView}
        exact
        viewName="Calendar"
        path="/calendar"
      />
    </Switch>
  );
};

export default Routes;
